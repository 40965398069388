<template>
	<div class="task-kanban mt-2 custom_font_family">
		<div class="kanban-view-list">
			<v-flex class="kanban-innerlist" v-for="(row, parent_index) in taskData" :key="parent_index">
				<div :class="`${row.status.status_color} lighten-4 list-items`">
					<div class="d-flex align-center">
						<div class="fw-600 title-text mx-2">{{ row.status.text }}</div>
						<div v-if="row.status && row.status.status_color">
							<v-chip class="" label :color="`${row.status.status_color} white--text`" x-small>
								{{ row.list.length }}
							</v-chip>
						</div>
						<v-spacer></v-spacer>
						<v-icon small class="me-2">{{ row.watch ? "mdi-eye" : "" }}</v-icon>
						<ListActionsMenu
							:parent-index="parent_index"
							:watch-status="row.watch"
							:show-edit="row.show_edit"
							@update:watch="UpdateWatch"
							@open:edit="OpenEdit"
						></ListActionsMenu>
					</div>
				</div>

				<div class="list_items">
					<template v-if="row.showTopField">
						<div
							class="px-2 pt-2"
							style="position: sticky; bottom: 0px; width: 100%; background-color: #f1f2f4"
						>
							<div class="mb-0" v-click-outside="(e) => onClickOutside(e, parent_index)">
								<v-textarea
									v-model.trim="newTaskTitle"
									class="rounded"
									auto-grow
									autofocus
									hide-details
									solo
									name="input-7-4"
									label="Enter a title for this card.."
									@keydown.enter="addCardEvent(parent_index)"
								></v-textarea>
								<div class="d-flex align-center pt-2">
									<v-btn depressed color="blue" class="white--text" @click="addCardEvent(parent_index)">
										Add card
									</v-btn>
									<v-btn small plain icon @click="row.showTopField = false">
										<v-icon>mdi-close</v-icon>
									</v-btn>
								</div>
							</div>
							<!-- <div class="mt-2 d-flex" v-else>
								<v-btn @click="row.show_editfield = true" block class="text-start"
									><v-icon size="18">mdi-plus</v-icon
									><span class="text-start" style="font-size: 12px">Add a card</span></v-btn
								>
							</div> -->
						</div>
					</template>

					<draggable group="people" class="overflow-list-item" v-model="row.list" draggable=".drag-item">
						<div v-for="(data, index) in row.list" :key="index" class="drag-item">
							<div class="px-2 pt-2 mb-1">
								<v-hover v-slot="{ hover }">
									<v-card
										style="border-radius: 8px"
										class="relative on-hover"
										:class="{ 'on-hover': hover }"
										@click="parentDialog = true"
									>
										<div style="background-color: grey">
											<v-img
												v-if="data && data.profile"
												:src="data.profile"
												max-width="208px"
												class="mx-auto"
												height="200px"
											></v-img>
										</div>
										<div class="ellipsis px-2 py-2">
											<div class="">
												<v-icon small>mdi-account-multiple</v-icon>
												<v-btn
													class="px-0"
													style="text-transform: capitalize; padding: 0px"
													plain
													color="darkgrey"
													>Business thrust > {{ row.status.text }}</v-btn
												>
											</div>
											<div>
												<v-fab-transition>
													<v-tooltip top>
														<template v-slot:activator="{ on, attrs }">
															<v-chip
																v-if="data && data.onPagelabel"
																@click.stop.prevent="hidden = !hidden"
																v-bind="attrs"
																v-on="on"
																style="min-width: 54px; height: 16px !important"
																x-small
																label
																class="mr-2 px-1"
																color="green"
																><span v-show="!hidden" :class="hidden" style="font-size: 12px">{{
																	data.onPagelabel
																}}</span>
															</v-chip>
														</template>
														<span>Color: green, title: "On Page"</span>
													</v-tooltip>
												</v-fab-transition>
												<v-fab-transition>
													<v-tooltip top>
														<template v-slot:activator="{ on, attrs }">
															<v-chip
																v-if="data && data.offPagelabel"
																@click.stop.prevent="hidden = true"
																v-bind="attrs"
																v-on="on"
																style="min-width: 54px; height: 16px !important"
																x-small
																label
																color="orange"
																class="px-1"
																><span v-show="!hidden" style="font-size: 12px">
																	{{ data.offPagelabel }}
																</span></v-chip
															>
														</template>
														<span>Color: orange, title: "Off Page"</span>
													</v-tooltip>
												</v-fab-transition>
											</div>
											<div class="ellipsis">
												<span class="pr-2 task-title">{{ data.taskTitle }}</span>
											</div>
											<div class="d-flex justify-space-between mt-1 flex-wrap" v-if="data && data.profile">
												<div>
													<v-tooltip bottom>
														<template v-slot:activator="{ on, attrs }">
															<v-btn v-bind="attrs" v-on="on" depressed style="padding: 0px !important"
																><v-icon size="16">mdi-clock</v-icon>Sep30</v-btn
															>
														</template>
														<span>This card is due later.</span>
													</v-tooltip>
													<v-tooltip bottom>
														<template v-slot:activator="{ on, attrs }">
															<v-btn
																plain
																v-bind="attrs"
																v-on="on"
																depressed
																style="padding: 0px !important; min-width: 20px !important"
																><v-icon size="16">mdi-playlist-edit</v-icon></v-btn
															>
														</template>
														<span>This card has a description.</span>
													</v-tooltip>
													<v-tooltip bottom>
														<template v-slot:activator="{ on, attrs }">
															<v-btn v-bind="attrs" v-on="on" depressed plain style="padding: 0px !important">
																<v-icon class="mx-1" size="16">mdi-comment</v-icon>1</v-btn
															>
														</template>
														<span>Comments</span>
													</v-tooltip>
													<v-tooltip bottom>
														<template v-slot:activator="{ on, attrs }">
															<v-btn v-bind="attrs" v-on="on" depressed plain style="padding: 0px !important">
																<v-icon class="mx-1" size="16">mdi-checkbox-multiple-marked-outline</v-icon>
																1/4
															</v-btn>
														</template>
														<span>Checklist items</span>
													</v-tooltip>
												</div>
												<div>
													<v-menu
														:nudge-width="250"
														left
														v-model="data.closeMemberMenu"
														offset-y
														:close-on-content-click="false"
													>
														<template v-slot:activator="{ on, attrs }">
															<v-avatar v-bind="attrs" v-on="on" color="blue" size="26">
																<span class="text-black text-h6 title_card">JD</span>
															</v-avatar>
														</template>
														<v-toolbar color="blue darken-4" dark>
															<v-btn color="cyan" fab dark x-large absolute bottom left>
																<div class="text-h3 text-black title_card">JD</div>
															</v-btn>
															<div class="pt-3" style="max-width: 80px; margin: 0 auto">
																<div class="text-h6 title_card">John Doe</div>
																<div>john@gmail.com</div>
															</div>
															<v-btn
																absolute
																top
																right
																class="me-1"
																@click="data.closeMemberMenu = false"
																small
																depressed
																icon
																><v-icon>mdi-close</v-icon></v-btn
															>
														</v-toolbar>
														<v-list two-line subheader>
															<v-subheader inset> </v-subheader>

															<v-list-item link>
																<v-list-item-content>
																	<v-list-item-title
																		style="font-weight: 400 !important; text-transform: capitalize !important"
																		class="py-1"
																		>View Profile</v-list-item-title
																	>
																</v-list-item-content>
															</v-list-item>

															<v-divider class="mx-2"></v-divider>
															<v-list-item link>
																<v-list-item-content>
																	<v-list-item-title
																		style="font-weight: 400 !important; text-transform: capitalize !important"
																		class="py-1"
																		>Remove from card</v-list-item-title
																	>
																</v-list-item-content>
															</v-list-item>
														</v-list>
													</v-menu>
												</div>
											</div>
										</div>
										<!-- <v-spacer></v-spacer>
										<v-btn
											:class="{ 'show-btns': hover }"
											:color="transparent"
											depressed
											style="position: absolute; top: 5px; right: 3px"
											small
											icon
										>
											<v-icon :class="{ 'show-btns': hover }" :color="transparent" small>mdi-pencil</v-icon>
										</v-btn> -->
									</v-card>
								</v-hover>
							</div>
						</div>
						<template v-if="!row.showTopField">
							<div
								class="px-2 py-2"
								ref="myElement"
								style="position: sticky; bottom: 0px; width: 100%; background: #ffffff; border-radius: 12px"
							>
								<div
									class="mb-2 mt-2"
									v-if="row.showBottomField"
									v-click-outside="(e) => onClickOutside(e, parent_index)"
								>
									<v-textarea
										v-model.trim="newTaskTitle"
										class="rounded"
										auto-grow
										autofocus
										hide-details
										solo
										name="input-7-4"
										label="Enter a title for this card.."
										@keydown.enter="addCardEvent(parent_index)"
									></v-textarea>
									<div class="d-flex align-center pt-2">
										<v-btn depressed color="blue" class="white--text" @click="addCardEvent(parent_index)">
											Add card
										</v-btn>
										<v-btn small plain icon @click="row.showBottomField = false">
											<v-icon>mdi-close</v-icon>
										</v-btn>
									</div>
								</div>
								<div class="mt-0 d-flex" v-else style="height: 100%">
									<v-btn @click="row.showBottomField = true" block class="text-start"
										><v-icon size="18">mdi-plus</v-icon
										><span class="text-start" style="font-size: 12px">Add a card</span></v-btn
									>
									<!-- <v-tooltip bottom>
										<template v-slot:activator="{ on, attrs }">
											<v-btn v-bind="attrs" v-on="on" depressed class="px-0"
												><v-icon size="16">mdi-movie-open-plus-outline</v-icon></v-btn
											>
										</template>
										<span>Create From template</span>
									</v-tooltip> -->
								</div>
							</div>
						</template>
					</draggable>
				</div>
			</v-flex>
		</div>
		<Dialog :dialog="parentDialog" :dialog-width="900" @close="parentDialog = false">
			<template v-slot:title>
				<div style="width: 100%" class="d-flex align-center justify-space-between task-dialog">
					<v-btn-toggle v-model="priority_status" tile group divided colo blue variant="plain" mandatory>
						<v-btn color="red" height="34" variant="flat" style="border: 2px solid #c42f22"> Low </v-btn>

						<v-btn color="green" variant="flat" style="border: 2px solid rgb(34, 167, 93)" height="34">
							Medium
						</v-btn>
						<v-btn color="blue" variant="flat" style="border: 2px solid #2196f3" height="34">
							Heigh
						</v-btn>
					</v-btn-toggle>
					<v-spacer></v-spacer>
					<v-tooltip>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								v-bind="attrs"
								v-on="on"
								@click="myDayEvent = !myDayEvent"
								:color="myDayEvent ? 'primary' : ''"
								small
								icon
								class="mr-2"
							>
								<v-icon>mdi-bullseye</v-icon>
							</v-btn>
						</template>
						<span>Add to My Day</span>
					</v-tooltip>
					<DueDateStatus></DueDateStatus>
					<v-btn @click="parentDialog = false" icon large>
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</div>
			</template>
			<template v-slot:body>
				<TaskAddItemDialog></TaskAddItemDialog>
			</template>
		</Dialog>
	</div>
</template>
<script>
//import { Container, Draggable } from "vue-smooth-dnd";
import draggable from "vuedraggable";
import { QUERY, PATCH } from "@/core/services/store/request.module";
import { filter, cloneDeep } from "lodash";
import { LeaveEventBus } from "@/core/lib/leave.form.lib";
import TaskAddItemDialog from "@/view/components/TaskAddItemDialog";
import DueDateStatus from "@/view/components/DueDateStatus";
import ListActionsMenu from "@/view/components/ListActionsMenu";
import { getCurrentUser } from "@/core/services/jwt.service";
import MomentJS from "moment-timezone";
import Dialog from "@/view/components/Dialog";
import "@/core/event-bus/click-outside";
//import TextAreaInput from "@/view/components/TextAreaInput";

export default {
	name: "LeaveKanban",
	data() {
		return {
			pageLoading: false,
			name_field: "Done",
			transparent: "rgba(255, 255, 255, 0)",
			priority_status: [0],
			myDayEvent: false,
			dropPlaceholderOptions: {
				className: "lead-drop-preview",
				animationDuration: "50",
				showOnTop: true,
			},
			active_list: "menu",
			list_dialogs: {
				add_card: false,
				copy_list: false,
				move_list: false,
				short_list: false,
			},
			hidden: false,
			isPVEdit: false,
			task_value: "",
			parentDialog: false,
			createTaskItemDialog: false,
			newTaskTitle: "",
			messages: [
				{
					from: "You",
					message: `Sure, I'll see you later.`,
					time: "10:42am",
					color: "deep-purple lighten-1",
				},
				{
					from: "John Doe",
					message: "Yeah, sure. Does 1:00pm work?",
					time: "10:37am",
					color: "green",
				},
				{
					from: "You",
					message: "Did you still want to grab lunch today?",
					time: "9:47am",
					color: "deep-purple lighten-1",
				},
			],

			//show_editfield: false,
			taskData: [
				{
					status: {
						id: 1,
						text: "To do",
						value: "1",
						type: 26,
						status_color: "orange",
						count: 0,
					},
					watch: false,
					showTopField: false,
					showBottomField: false,
					show_editfield: false,
					list: [
						{
							id: 1,
							taskTitle: "Project planing1",
							onPagelabel: "On Page",
							offPagelabel: "Off Page",
							start_date: "2023-09-25",
							end_date: "2023-09-28",
							profile: "https://cdn.vuetifyjs.com/images/cards/sunshine.jpg",
							closeMemberMenu: false,
							//show_editfield: false,
						},
					],
				},
				{
					status: {
						id: 2,
						text: "In-Progress",
						value: "2",
						type: 26,
						status_color: "green",
						count: 0,
					},
					watch: false,
					showTopField: false,
					showBottomField: false,
					show_editfield: false,
					list: [
						{
							id: 2,
							taskTitle: "Project planing2",
							onPagelabel: "On Page",
							offPagelabel: "Off Page",
							start_date: "2023-09-25",
							end_date: "2023-09-28",
							reason: "kjl",
							profile: "https://cdn.vuetifyjs.com/docs/images/cards/hands.jpg",
							closeMemberMenu: false,
							//show_editfield: false,
						},
					],
				},
				{
					status: {
						id: 3,
						text: "Completed",
						value: "3",
						type: 26,
						status_color: "success",
						count: 0,
					},
					watch: false,
					showTopField: false,
					showBottomField: false,
					show_editfield: false,
					list: [
						{
							id: 4,
							taskTitle: "Project planing3",
							onPagelabel: "",
							offPagelabel: "Off Page",
							start_date: "2023-09-25",
							end_date: "2023-09-28",
							reason: "kjl",
							profile: "https://cdn.vuetifyjs.com/docs/images/cards/singer.jpg",
							closeMemberMenu: false,
							//show_editfield: false,
						},
					],
				},
				{
					status: {
						id: 3,
						text: "On-hold",
						value: "3",
						type: 26,
						status_color: "blue",
						count: 0,
					},
					watch: false,
					showTopField: false,
					showBottomField: false,
					show_editfield: false,
					list: [
						{
							id: 5,
							taskTitle: "Project planing4",
							onPagelabel: "On Page",
							offPagelabel: "Off Page",
							start_date: "2023-09-25",
							end_date: "2023-09-28",
							reason: "kjl",
							profile: "https://cdn.vuetifyjs.com/images/cards/sunshine.jpg",
							closeMemberMenu: false,
							//show_editfield: false,
						},
					],
				},
				{
					status: {
						id: 3,
						text: "Cancel",
						value: "3",
						type: 26,
						status_color: "red",
						count: 0,
					},
					watch: false,
					showTopField: false,
					showBottomField: false,
					show_editfield: false,
					list: [
						{
							id: 5,
							taskTitle: "Project planing5",
							onPagelabel: "On Page",
							offPagelabel: "",
							start_date: "2023-09-25",
							end_date: "2023-09-28",
							reason: "kjl",
							profile: "https://cdn.vuetifyjs.com/docs/images/cards/hands.jpg",
							closeMemberMenu: false,
							//show_editfield: false,
						},
					],
				},
			],
			menuitems: [
				{
					id: 1,
					actionType: "event1",
					title: "Add card...",
				},
				{
					id: 2,
					actionType: "event2",
					title: "Copy list...",
				},
				{
					id: 3,
					actionType: "event3",
					title: "Move list...",
				},
				{
					id: 4,
					actionType: "event4",
					title: "Watch",
				},
				{
					id: 5,
					actionType: "event5",
					title: "Short by...",
				},
			],

			statusvalue: 0,
			formValid: true,
			leaveId: 0,
			statusdata: null,
			probisionTime: 0,
			confirm_dialog: false,
			confirm: false,
			delURL: null,
			dropObjectData: {},
			statusDialog: false,
			leaveBarcode: null,
			status_remark: {
				drop_status: "Cancelled",
				dropData: null,
				drop_value: 0,
			},
			delTEXT: null,
		};
	},
	watch: {
		$route: {
			handler() {
				const { query } = this.$route;
				this.getLeaveListing(query);
			},
		},
	},

	methods: {
		UpdateWatch(param, status) {
			//console.log({ param, status });
			this.taskData[param].watch = !status;
		},
		OpenEdit(param) {
			this.taskData[param].showTopField = true;
			this.taskData[param].showBottomField = false;
		},
		backToList() {
			this.active_list = "menu";
		},
		listactionsEvent(item) {
			this.active_list = item.actionType;
		},

		goToAddTaskDialog() {
			this.createTaskItemDialog = true;
		},
		addCardEvent(index) {
			// Check if this.newTaskTitle is not empty
			if (this.newTaskTitle) {
				// Use push or unshift based on the condition
				if (this.taskData[index].showBottomField) {
					// If showBottomField is true, use push to add to the end of the array
					this.taskData[index].list.push({
						taskTitle: this.newTaskTitle,
						onPagelabel: "",
						offPagelabel: "",
						start_date: "",
						end_date: "",
						profile: "",
						closeMemberMenu: false,
						show_editfield: false,
					});
				} else {
					// Otherwise, use unshift to add to the beginning of the array
					this.taskData[index].list.unshift({
						taskTitle: this.newTaskTitle,
						onPagelabel: "",
						offPagelabel: "",
						start_date: "",
						end_date: "",
						profile: "",
						closeMemberMenu: false,
						show_editfield: false,
					});
				}
				this.newTaskTitle = null;
				//this.show_editfield = false;
			}
		},
		onClickOutside(e, index) {
			// Check if this.newTaskTitle is not empty
			if (this.newTaskTitle) {
				this.addCardEvent(index);
				this.taskData[index].showBottomField = false;
				this.taskData[index].showTopField = false;
				//console.log("ujsbv");
			} else {
				// Check if the click is inside the component
				e.preventDefault();
				this.taskData[index].showBottomField = false;
				this.taskData[index].showTopField = false;
				return false;
			}
		},
		closeDialog() {
			this.statusDialog = false;
		},
		openStatusDialog() {
			this.statusDialog = true;
		},
		onSubmit() {
			this.confirm = true;
			this.dropData();
			this.statusDialog = false;
		},
		closeMainDialog() {
			this.confirm = false;
			this.statusDialog = false;
		},
		refresh() {
			this.confirm_dialog = false;
			this.getLeaveListing();
		},
		Updateleave(id) {
			this.$emit("updateData", id);
		},
		delete_leave(row) {
			this.delTEXT = row.barcode;
			(this.delURL = `leave/${row.id}`), (this.confirm_dialog = true);
			this.getLeaveListing();
		},
		getProvision(proDate) {
			let a = MomentJS();
			let b = MomentJS(proDate);
			return b.diff(a, "days");
			/* alert(this.probisionTime) */
		},
		getLeaveListing(query) {
			this.pageLoading = true;
			this.$store
				.dispatch(QUERY, {
					url: `leave`,
					data: {
						status: "all",
						...query,
					},
				})
				.then((data) => {
					const output = [];
					for (let i = 0; i < data.status.length; i++) {
						if (data.status[i].value == 4) {
							continue;
						}
						output.push({
							status: data.status[i],
							list: filter(data.tbody, { status: data.status[i].text }),
						});
					}
					//this.leaveData = output;
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		dropData() {
			if (this.confirm == true) {
				this.update_loading = true;
				this.$store
					.dispatch(PATCH, { url: `leave/${this.leaveId}/${this.statusdata}` })
					.then(() => {
						this.getLeaveListing();
						this.$emit("refressData", true);
						this.$emit("refressStatus", true);
					})
					.catch((error) => {
						this.logError(error);
					})
					.finally(() => {
						this.update_loading = false;
					});
			}
			if (this.confirm == false) {
				this.leaveData[this.dropObjectData.index].list = this.applyDrag(
					this.dropObjectData.collection,
					this.dropObjectData.dropResult
				);
				this.getLeaveListing();
				return false;
			}
		},
		onDrop(index, collection, dropResult, dropStatus) {
			if (this.is_staff) {
				return false;
			}
			const { addedIndex } = dropResult;

			if (addedIndex != null) {
				this.statusdata = dropStatus;
			}

			if (this.dragstatus != "To Do") {
				this.leaveData[index].list = this.applyDrag(collection, dropResult);
				this.getLeaveListing();
				return false;
			}
			if (addedIndex != null) {
				this.statusDialog = true;
				this.dropObjectData = {
					collection,
					dropResult,
					index,
				};
			}
			/* this.leaveData[index].list = this.applyDrag(collection, dropResult); */
		},
		getChildPayload(row, index, event) {
			this.leaveId = row[event].id;
			this.leaveBarcode = row[event].barcode;
			this.dragstatus = row[event].status;
			return this.leaveData[index];
		},

		applyDrag(output, dragResult) {
			const { removedIndex, addedIndex, payload } = dragResult;
			if (removedIndex === null && addedIndex === null) return output;
			const result = cloneDeep(output);
			let itemToAdd = payload.list[addedIndex];
			if (removedIndex !== null) {
				itemToAdd = result.splice(removedIndex, 1)[0];
			}
			if (addedIndex !== null) {
				result.splice(addedIndex, 0, itemToAdd);
			}
			return result;
		},
	},
	computed: {
		is_staff() {
			const user = getCurrentUser();
			const role_name = user.role;
			return role_name == "Staff";
		},
	},
	mounted() {
		this.getLeaveListing();
		LeaveEventBus.$on("refresh", () => {
			this.getLeaveListing();
		});
	},
	components: {
		Dialog,
		TaskAddItemDialog,
		draggable,
		DueDateStatus,
		ListActionsMenu,
		//TextAreaInput,
	},
};
</script>

<style scoped>
.custom_font_family {
	/* color: #172b4d; */
	font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Droid Sans,
		Helvetica Neue, sans-serif !important;
	font-size: 14px !important;
	font-weight: 400 !important;
	line-height: 20px;
}
/* .layout.section-main-content {
    height: calc(100vh - 348px);
} */
.overflow-list-item {
	overflow-y: auto;
	max-height: calc(100vh - 315px) !important;
	height: auto;
	min-height: 100%;
}
.kanban-view-list .kanban-innerlist {
	min-width: 323px;
	width: calc(20% - 12px);
	margin: 0px 6px;
}
/* .kanban-view-list .kanban-innerlist .smooth-dnd-container.vertical {
	height: calc(100vh - 230px);
} */
.list_items {
	background-color: #ffffff;
	/* background: linear-gradient(to right, #eff3f7, #eff3f7); */
	border-radius: 12px;
	max-height: 100%;
	/* height: calc(100vh - 230px) !important; */
}

.show-btns {
	color: rgba(255, 255, 255, 1) !important;
}
.task-dialog {
	font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Droid Sans,
		Helvetica Neue, sans-serif !important;
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
}
.task-dialog
	button.v-btn.v-item--active.v-btn--active.v-btn--is-elevated.v-btn--has-bg.theme--light.v-size--default.red {
	background-color: #c42f22 !important;
	color: #fff;
}
.task-dialog
	button.v-btn.v-item--active.v-btn--active.v-btn--is-elevated.v-btn--has-bg.theme--light.v-size--default.green {
	background-color: rgb(34, 167, 93) !important;
	color: #fff;
}
.task-dialog
	button.v-btn.v-item--active.v-btn--active.v-btn--is-elevated.v-btn--has-bg.theme--light.v-size--default.blue {
	background-color: rgb(33, 150, 243) !important;
	color: #fff;
}
.on-hover {
	border: 2px solid #fff;
	box-sizing: border-box;
}
.on-hover:hover {
	border: 2px solid #000;
	box-sizing: border-box;
	border-radius: 8px;
}
.task-title {
	font-size: 16px;
	font-weight: 500;
	line-height: 20px;
}
</style>
